import React, { Component, RefObject } from 'react'
import { Pressable, Platform, View, Text, Image } from 'react-native'
import {
    Menu,
    MenuProvider,
    MenuOptions,
    MenuOption,
    MenuTrigger,
} from 'react-native-popup-menu'
import COLORS from '../../utils/colors'
import STRINGS from '../../utils/strings'
import styles from './styles'
import { optionsStyles, nestedOptionsStyles } from './styles'
import { View as RnView } from 'react-native-web'

interface ContextMenuProps {
    options: {
        id: any
        label: string
        leftIcon?: React.ReactNode
        rightIcon?: React.ReactNode
    }[]
    nestedOptions?: {
        id: any
        label: string
    }[]
    onPress: (mainOption: any, nestedOption?: any) => void
    OptionsTextStyle?: {}
    Icon?: React.ReactNode
    IconHighlighted?: React.ReactNode
    IconLeft?: React.ReactNode
    IconRight?: React.ReactNode
    popupPosition?: 'left' | 'right'
    menuOptionStyles?: object
    menuProviderStyle?: object
    submenuContainerStyle?: object
    optionsContainerStyle?: object
    testID?: string
}

interface ContextMenuState {
    isMenuOpen: boolean
    selectedOption: number | null
    isSubmenuOpen: boolean
}

export const getColorByText = (label: string): string => {
    switch (label) {
        case 'Edit':
            return COLORS.COLOR_COOLGRAY
        case 'Delete':
            return COLORS.COLOR_RED
        default:
            return COLORS.COLOR_COOLGRAY
    }
}

export const getBackgroundColor = (
    isSubmenuOpen?: boolean,
    selectedOption?: number,
    option?: any,
) => {
    if (
        isSubmenuOpen &&
        selectedOption === option.id &&
        option.rightIcon !== ''
    ) {
        return COLORS.COLOR_LIGHTGRAY
    } else if (selectedOption === option.id) {
        return COLORS.COLOR_LIGHTPINK
    } else {
        return ''
    }
}

class ContextMenu extends Component<ContextMenuProps, ContextMenuState> {
    containerRef: RefObject<any>
    outsideClickListener: (event: Event) => void

    constructor(props: ContextMenuProps) {
        super(props)

        this.state = {
            isMenuOpen: false,
            selectedOption: null,
            isSubmenuOpen: false,
        }

        this.containerRef = React.createRef<RnView>()
        this.outsideClickListener = this.handlePressOutside.bind(this)
    }

    handleMenuOpen = () => {
        this.setState({ isMenuOpen: true, isSubmenuOpen: false })
    }

    handleMenuClose = () => {
        this.setState({
            isMenuOpen: false,
            isSubmenuOpen: false,
            selectedOption: null,
        })
    }

    handleOptionPress = (option: any) => {
        this.setState({ selectedOption: option.id, isSubmenuOpen: true })
    }

    handlePressOutside = (event: any) => {
        if (
            this.containerRef.current &&
            !this.containerRef.current.contains(event.target)
        ) {
            this.handleMenuClose()
        }
    }

    componentDidMount() {
        if (Platform.OS === 'web') {
            document.addEventListener('mousedown', this.handlePressOutside)
        }
    }

    componentWillUnmount() {
        if (Platform.OS === 'web') {
            document.removeEventListener('mousedown', this.handlePressOutside)
        }
    }

    getPositionStyle = () => {
        const { popupPosition } = this.props
        return popupPosition === 'right'
            ? styles.containerRight
            : styles.containerLeft
    }

    renderOptions = () => {
        const {
            options,
            nestedOptions,
            OptionsTextStyle,
            menuOptionStyles,
            submenuContainerStyle,
            onPress,
        } = this.props

        return options.map((option) => {
            const { id, label, rightIcon, leftIcon } = option
            const mainOption = { id, label }

            return (
                <MenuOption
                    key={id}
                    style={[
                        styles.menuOptionStyle,
                        {
                            backgroundColor: getBackgroundColor(
                                this.state.isSubmenuOpen,
                                this.state.selectedOption,
                                option,
                            ),
                        },
                        menuOptionStyles,
                    ]}
                >
                    <Pressable
                        style={[styles.optionContainer]}
                        onPress={() => {
                            this.handleOptionPress(option)
                            onPress && onPress(mainOption)
                        }}
                    >
                        <View style={styles.imageView}>
                            <View style={{ width: 6 }}>
                                {this.props.IconLeft || leftIcon || null}
                            </View>
                            <Text
                                style={[
                                    styles.optionText,
                                    { color: getColorByText(label) },
                                    OptionsTextStyle,
                                ]}
                            >
                                {label}
                            </Text>
                        </View>
                        {this.props.IconRight || rightIcon || null}
                    </Pressable>
                    {rightIcon !== '' &&
                        this.state.selectedOption === id &&
                        this.state.isSubmenuOpen && (
                            <View
                                style={[
                                    styles.submenuContainer,
                                    submenuContainerStyle,
                                ]}
                            >
                                <MenuOptions customStyles={nestedOptionsStyles}>
                                    {nestedOptions?.map((nestedOption) => {
                                        return (
                                            <MenuOption key={nestedOption.id}>
                                                <Pressable
                                                    style={
                                                        styles.optionContainer
                                                    }
                                                    onPress={() =>
                                                        onPress &&
                                                        onPress(
                                                            mainOption,
                                                            nestedOption,
                                                        )
                                                    }
                                                >
                                                    <Text
                                                        style={[
                                                            styles.optionText,
                                                            OptionsTextStyle,
                                                        ]}
                                                    >
                                                        {nestedOption.label}
                                                    </Text>
                                                </Pressable>
                                            </MenuOption>
                                        )
                                    })}
                                </MenuOptions>
                            </View>
                        )}
                </MenuOption>
            )
        })
    }

    render() {
        const { isMenuOpen } = this.state

        const { Icon, IconHighlighted } = this.props

        return (
            <View style={styles.container}>
                <Pressable testID={STRINGS.CONTEXTMENU_TESTID}>
                    <MenuProvider
                        skipInstanceCheck
                        style={[
                            styles.menuProviderStyles,
                            this.props.menuProviderStyle,
                        ]}
                    >
                        <Menu
                            //testID="menu"
                            onOpen={this.handleMenuOpen}
                            onClose={this.handleMenuClose}
                            opened={isMenuOpen}
                        >
                            <MenuTrigger
                                onPress={() =>
                                    this.setState({ isMenuOpen: !isMenuOpen })
                                }
                            >
                                <View
                                    style={[
                                        styles.highlightedIconView,
                                        {
                                            backgroundColor: isMenuOpen
                                                ? COLORS.COLOR_LIGHTGRAY
                                                : COLORS.COLOR_WHITE,
                                        },
                                    ]}
                                >
                                    {Icon ? (
                                        isMenuOpen ? (
                                            IconHighlighted
                                        ) : (
                                            Icon
                                        )
                                    ) : (
                                        <Image
                                            resizeMode={'contain'}
                                            style={[
                                                styles.icon,
                                                isMenuOpen &&
                                                    styles.highlightedIcon,
                                            ]}
                                            source={{
                                                uri: 'https://i.imgur.com/fEsFjVT.png',
                                            }}
                                        />
                                    )}
                                </View>
                            </MenuTrigger>
                            {!isMenuOpen ? null : (
                                <View
                                    ref={this.containerRef}
                                    style={[
                                        this.getPositionStyle(),
                                        optionsStyles.optionsContainer,
                                        this.props.optionsContainerStyle,
                                    ]}
                                >
                                    <MenuOptions>
                                        {this.renderOptions()}
                                    </MenuOptions>
                                </View>
                            )}
                        </Menu>
                    </MenuProvider>
                </Pressable>
            </View>
        )
    }
}

export default ContextMenu
