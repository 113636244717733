//@ts-nocheck
import React, { Component } from 'react'
import { View, ScrollView, Text } from 'react-native'
import DynamicRow from './component/DynamicRow'
import style from './style'
import contextMenuStyle from '../ContextMenu/styles'
import getPlatformType from '../../utils/getPlatformType'
import { DataTableProps } from './interface'

interface state {
    updatedHeaderData?: any
}

class DataTable extends Component<DataTableProps, state> {
    constructor(props) {
        super(props)
        this.state = {
            updatedHeaderData: [...this.props.headerData],
        }
    }

    renderRow = ({ item, index, length }: any) => {
        const {
            tableDataContainerStyle,
            tableDataTextStyle,
            renderItem,
            ContextMenu,
        } = this.props

        return (
            <DynamicRow
                rowData={item}
                tableDataContainerStyle={tableDataContainerStyle}
                tableDataTextStyle={tableDataTextStyle}
                length={length}
                index={index}
                headerData={this?.state?.updatedHeaderData}
                ContextMenu={ContextMenu}
                renderItem={renderItem}
                testID={`dataTableRow-${index}`}
            />
        )
    }

    renderRows = (tableData: Array<Object>) => {
        return tableData?.map((item, index) => {
            return this.renderRow({
                item,
                index,
                length: tableData.length,
            })
        })
    }

    renderRowsMobile = (tableData: Array<Object>) => {
        return (
            <ScrollView
                horizontal={true}
                showsHorizontalScrollIndicator={false}
                contentContainerStyle={style.mobileTableContent}
            >
                <ScrollView>
                    {this.renderRows(tableData)}
                </ScrollView>
            </ScrollView>
        )
    }

    render() {
        const {
            tableData,
            tableTitle,
            headerDataContainerStyle,
            headerDataTextStyle,
            titleStyle,
            titleContainerStyle,
            containerStyle,
            ContextMenu,
        } = this.props
        const { updatedHeaderData } = this.state

        const tableRows =
            getPlatformType() == 'phone'
                ? this.renderRowsMobile(tableData)
                : this.renderRows(tableData)

        return (
            <View style={[style.container, containerStyle]}>
                <View style={[style.header, headerDataContainerStyle]}>
                    {updatedHeaderData?.map(
                        (headerValue: string, index: number) => (
                            <Text
                                style={[style.headerText, headerDataTextStyle]}
                                key={index}
                            >
                                {headerValue}
                            </Text>
                        ),
                    )}
                    {ContextMenu && (
                        <View
                            style={[
                                contextMenuStyle.container,
                                style.autoWidthCell,
                                headerDataContainerStyle,
                            ]}
                        ></View>
                    )}
                </View>
                {tableTitle && (
                    <View style={[style.textStyle, titleContainerStyle]}>
                        <Text style={[style.titleStyle, titleStyle]}>
                            {tableTitle}
                        </Text>
                    </View>
                )}
                {tableRows}
            </View>
        )
    }
}

export default DataTable
